import { createContext, useState } from "react";
import { Backdrop, CircularProgress } from '@mui/material'
import { Alert } from "../components/organisms/dialog/Alert";
import { getAppVersion } from "../config/version";

class ShowAlert {
   constructor(setAlertData) {
      this.setAlertData = setAlertData
   }

   success(message = '') {
      this.setAlertData({
         active: true,
         type: 'success',
         title: 'Tudo certo',
         message
      })
   }

   error(message = '') {
      this.setAlertData({
         active: true,
         type: 'error',
         title: 'Houve um problema',
         message
      })
   }

   info(title = '', message = '') {
      this.setAlertData({
         active: true,
         type: 'info',
         title,
         message
      })
   }
}

export const AppContext = createContext({});

export const AppProvider = ({ children }) => {
   const appVersion = getAppVersion()
   const [loading, setLoading] = useState(false)
   const [alertData, setAlertData] = useState({
      active: false,
      type: '',
      title: '',
      message: ''
   })

   const alert = new ShowAlert(setAlertData)

   return (
      <AppContext.Provider value={{ setLoading, loading, alert, appVersion }}>
         {children}
         <Alert
            active={alertData.active}
            type={alertData.type}
            title={alertData.title}
            message={alertData.message}
            handleClose={() => setAlertData({
               active: false,
               type: '',
               title: '',
               message: ''
            })}
         />
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
         >
            <CircularProgress color="inherit" />
         </Backdrop>
      </AppContext.Provider>
   )
}