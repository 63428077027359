import { useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import { useHistory, useParams } from "react-router-dom";
import { formatTimestamp } from "../../../helpers/user/formatTimestamp";
import api from "../../../services/api";
import {
  Box,
  ContentContainer,
  SectionHeader,
  Text,
  TextField,
} from "../../W3libComponents";
import { Grid, Spacer } from "../../atoms";
import { B2CLabel } from "../../atoms/B2CLabel";
import { PrimaryButton, SecondaryButton } from "../../organisms/button/Buttons";
import { Colors } from "../../organisms/layout/Colors";
import { SmallSectionTitle } from "../../organisms/layout/SmallSectionTitle";

export const LockerEdit = ({ context }) => {
  const { id } = useParams();
  const newLocker = id === "new" ? true : false;
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [storeLockerData, setStoreLockerData] = useState(null);
  const [showDetails, setShowDetails] = useState({ active: false, id: null });
  const [lockerWasUpdated, setLockerWasUpdated] = useState(false);
  const [B2CLocker, setB2CLocker] = useState(false);
  const [locker, setLocker] = useState({
    name: "",
    active: false,
    description: "",
    manufacturer: "",
    address: {
      bairro: "",
      cep: "",
      cidade: "",
      complemento: "",
      estado: "",
      numero: "",
      rua: "",
    },
    idLoopBox: "",
  });

  const { setLoading, alert, user } = context;
  const history = useHistory();
  const productsContainer = useRef();

  useEffect(() => {
    if (newLocker) {
      document.title = `TuimBox Admin - Novo Locker`;
      return;
    }

    loadLocker();
    loadProducts();
  }, []);

  useEffect(() => {
    setLockerWasUpdated(true);
  }, [locker]);

  const loadLocker = async () => {
    setLoading(true);
    api
      .get(`/v3/admin/locker/${id}`)
      .then((response) => {
        document.title = `TuimBox Admin - Locker ${response?.data?.locker?.name}`;
        setLocker(response.data.locker);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const loadProducts = async () => {
    setLoading(true);
    api
      .get(`/v3/admin/products?fields=urlImg,name,designationSize,cost`)
      .then((response) => {
        setLoading(false);
        setProducts(response.data.products);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (
      name === "name" ||
      name === "description" ||
      name === "manufacturer" ||
      name === "idLoopBox" ||
      name === "active" ||
      name === "boxCode" ||
      name === "freeRents" ||
      name === "freeHours" ||
      name === "serial" ||
      name === "freeDailyRents"
    ) {
      setLocker({
        ...locker,
        [name]: value,
      });
    } else {
      setLocker({
        ...locker,
        address: {
          ...locker.address,
          [name]: value,
        },
      });
    }
  };

  const handleSave = async () => {
    if (newLocker) {
      handleCreate();
      return;
    }

    setLoading(true);
    await api
      .patch(`/v3/admin/locker/${id}`, { locker })
      .then((response) => {
        alert.success("Locker atualizado com sucesso!");
        setLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";

        if (error?.response?.data?.error?.errors) {
          Object.values(error?.response?.data?.error?.errors).forEach(
            (error) => {
              errorMessage += `${error?.message} `;
            }
          );
        }
        alert.error(errorMessage.trim());
        setLoading(false);
      });
  };

  const handleCreate = async () => {
    setLoading(true);
    await api
      .post(`/v3/admin/locker`, { locker })
      .then((response) => {
        setLoading(false);
        alert.success("Locker criado com sucesso!");
        if (!response?.data?.data?._id) return;

        window.open(`/lockers/${response.data.data._id}`, "_self");
      })
      .catch((err) => {
        console.log(err);
        alert.error("Desculpe. Tivemos um problema na criação do locker.");
        setLoading(false);
      });
  };

  const handleDeleteLocker = async () => {
    setLoading(true);
    !newLocker &&
      (await api
        .delete(`/v3/admin/locker/${id}`)
        .then((response) => {
          alert.success("Locker excluído com sucesso!");
          history.push(`/lockers`);
          setLoading(false);
        })
        .catch((err) => {
          alert.error("Desculpe. Não foi possível excluir o locker.");
          setLoading(false);
        }));
  };

  const handleChangeActivation = () => {
    setLocker({
      ...locker,
      active: !locker.active,
    });
  };

  const handleSelectProduct = (product) => {
    selectedProduct?._id === product._id
      ? setSelectedProduct(null)
      : setSelectedProduct(product);
  };

  const handleStoreProduct = async () => {
    let lockerPrefix = `0${locker.idLoopBox}`.slice(-2);

    const data = {
      id_product: selectedProduct._id,
      orderNumber: "admin",
      lockerPrefix,
      boxCode: locker?.boxCode || "",
      endCustomer: {
        cellphone: "11942878453",
        email: "jason@tuim.com.br",
        fullName: "Jason Nona",
      },
      designationSize: selectedProduct?.designationSize || "",
      userId: user._id,
      lockerId: id,
    };

    try {
      setLoading(true);
      const response = await api.post(
        `/v3/app/locker/opendoor?type=store`,
        data
      );
      setStoreLockerData(response.data.data);
    } catch (error) {
      alert.error(
        "Desculpe. Não foi possível iniciar o abastecimento do locker."
      );
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const HeaderSubtitle = () => (
    <Box sx={{ display: "flex", gap: 1 }}>
      {locker?.idLoopBox ? (
        <Text>{`ID LoopBox: ${locker.idLoopBox}`}</Text>
      ) : (
        <></>
      )}
      {B2CLocker ? <B2CLabel /> : <></>}
    </Box>
  );

  return (
    <>
      <SectionHeader
        title={`Locker ${locker?.name}`}
        subtitle={<HeaderSubtitle />}
        // subtitle={B2CLocker ? <B2CLabel /> : ``}
        saveButton={lockerWasUpdated}
        saveButtonAction={handleSave}
        deleteButton={!newLocker}
        deleteButtonAction={handleDeleteLocker}
      />

      <Box sx={{ display: "flex", width: "100%" }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", gap: 3 }}>
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 3, flex: 1 }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "row", gap: 3, flex: 1 }}
              >
                <ContentContainer fullWidth>
                  <SmallSectionTitle title="Dados do Locker" />
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <TextField
                        variant="standard"
                        label="Nome"
                        refs="name"
                        name="name"
                        margin="dense"
                        value={locker?.name}
                        autoFocus
                        onChange={handleChange}
                        InputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{ style: { color: "#888" } }}
                      />
                      <Spacer horizontal />
                      <TextField
                        variant="standard"
                        label="Box Code"
                        refs="boxCode"
                        name="boxCode"
                        margin="dense"
                        value={locker?.boxCode || ""}
                        onChange={handleChange}
                        InputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{ style: { color: "#888" } }}
                        style={{ maxWidth: 90 }}
                      />
                      <Spacer horizontal />
                      <TextField
                        variant="standard"
                        label="ID LoopBox"
                        refs="idLoopBox"
                        name="idLoopBox"
                        margin="dense"
                        value={locker?.idLoopBox}
                        onChange={handleChange}
                        InputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{ style: { color: "#888" } }}
                        style={{ maxWidth: 90 }}
                      />
                    </Box>
                    <TextField
                      variant="standard"
                      label="Descrição"
                      refs="description"
                      name="description"
                      margin="dense"
                      value={locker?.description}
                      onChange={handleChange}
                      InputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{ style: { color: "#888" } }}
                    />
                    <TextField
                      variant="standard"
                      label="Serial"
                      refs="serial"
                      name="serial"
                      margin="dense"
                      value={locker?.serial || ""}
                      onChange={handleChange}
                      InputProps={{ shrink: true, style: { fontSize: 14 } }}
                      InputLabelProps={{
                        style: { color: "#888" },
                      }}
                    />
                    <TextField
                      variant="standard"
                      label="Fabricante"
                      refs="manufacturer"
                      name="manufacturer"
                      margin="dense"
                      value={locker?.manufacturer}
                      onChange={handleChange}
                      InputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{ style: { color: "#888" } }}
                    />
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <TextField
                        variant="standard"
                        label="Locações Mensais"
                        refs="freeRents"
                        name="freeRents"
                        margin="dense"
                        value={locker?.freeRents ?? 0}
                        onChange={handleChange}
                        InputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{ style: { color: "#888" } }}
                      />
                      <TextField
                        variant="standard"
                        label="Locações Diárias"
                        refs="freeDailyRents"
                        name="freeDailyRents"
                        margin="dense"
                        value={locker?.freeDailyRents ?? 0}
                        onChange={handleChange}
                        InputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{ style: { color: "#888" } }}
                      />
                      <TextField
                        variant="standard"
                        label="Duração (hrs)"
                        refs="freeHours"
                        name="freeHours"
                        margin="dense"
                        value={locker?.freeHours ?? 0}
                        onChange={handleChange}
                        InputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{ style: { color: "#888" } }}
                      />
                    </Box>
                    <TextField
                      fullWidth
                      variant="standard"
                      label="QR code link"
                      refs="qrCodeUrl"
                      name="qrCodeUrl"
                      margin="dense"
                      value={locker?.qrCodeUrl || ""}
                      onChange={handleChange}
                      InputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{ style: { color: "#888" } }}
                      disabled
                    />
                  </Box>
                </ContentContainer>

                {locker?.manufacturer === "Chavi" && !newLocker && (
                  <ContentContainer
                    style={{
                      minWidth: 280,
                      display: "flex",
                    }}
                  >
                    <SmallSectionTitle title="Histórico da Rede" />
                    {locker?.networkHistory?.length > 0 ? (
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          flexDirection: "column",
                          maxHeight: 424,
                          overflow: "auto",
                        }}
                      >
                        {locker?.networkHistory?.map((item, index) => {
                          const showLogDetails =
                            showDetails?.id === item?._id &&
                            showDetails?.active;
                          const event = item.updatedFields[0];
                          const online = event.networkStatus === "online";
                          return (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                gap: 2,
                                flexDirection: "row",
                                alignItems: "start",
                                // backgroundColor: "lightblue",
                                height: "100%",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 1,
                                  alignItems: "center",
                                  justifyContent: "center",
                                  position: "relative",
                                  // backgroundColor: "bisque",
                                  height: "100%",
                                  paddingBottom: 2,
                                }}
                              >
                                <Box
                                  sx={{
                                    minWidth: 12,
                                    minHeight: 12,
                                    borderRadius: "50%",
                                    marginTop: 0.5,
                                    backgroundColor: online
                                      ? Colors.lemonGreen
                                      : Colors.red,
                                  }}
                                />
                                {/* Linha Pontilhada */}
                                <Box
                                  sx={{
                                    borderLeft: "1px dashed #ccc",
                                    // height: "50px", // Ajuste conforme necessário
                                    height: "100%",
                                    // position: "absolute",
                                    // top: "28px",
                                    // left: "50%",
                                  }}
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 1,
                                  flexDirection: "column",
                                  position: "relative",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Text
                                    style={{
                                      whiteSpace: "wrap",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {item?.description}
                                  </Text>
                                  <Text small style={{ color: "darkgray" }}>
                                    {formatTimestamp({
                                      timestamp: item?.createdAt,
                                      showSeconds: true,
                                    })}
                                  </Text>
                                </Box>
                                {!online && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: 1,
                                      flexDirection: "column",
                                      position: "relative",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        gap: 1,
                                        alignItems: "center",
                                        "&:hover": {
                                          opacity: 0.8,
                                          cursor: "pointer",
                                        },
                                      }}
                                      onClick={() =>
                                        setShowDetails({
                                          active: !showDetails?.active,
                                          id: item?._id,
                                        })
                                      }
                                    >
                                      <Text small>Detalhes</Text>
                                      <Box
                                        sx={{
                                          backgroundSize: "cover",
                                          backgroundRepeat: "no-repeat",
                                          backgroundPosition: "center",
                                          backgroundImage: `url('/icons/gray_arrow_down.png')`,
                                          transform: showLogDetails
                                            ? "rotate(0)"
                                            : "rotate(-90deg)",
                                          transition: ".3s",
                                          width: 14,
                                          height: 14,
                                        }}
                                      />
                                    </Box>
                                    {showLogDetails && (
                                      <Box
                                        sx={{
                                          position: "absolute",
                                          top: 20,
                                          zIndex: 999,
                                        }}
                                      >
                                        <Box
                                          key={index}
                                          sx={{
                                            display: "flex",
                                            gap: 1,
                                            flexDirection: "column",
                                            backgroundColor:
                                              Colors.lightBackground,
                                            padding: "10px 12px",
                                            borderRadius: 2,
                                            minWidth: 100,
                                          }}
                                        >
                                          <Box sx={{ display: "flex", gap: 1 }}>
                                            <Text bold small>
                                              Status:
                                            </Text>
                                            <Text small>
                                              {event?.networkStatus}
                                            </Text>
                                          </Box>
                                          <Box sx={{ display: "flex", gap: 1 }}>
                                            <Text bold small>
                                              Erro:
                                            </Text>
                                            <Text small>
                                              {event?.error || "-"}
                                            </Text>
                                          </Box>
                                        </Box>
                                      </Box>
                                    )}
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    ) : (
                      <Box
                        style={{
                          display: "flex",
                          flex: 1,
                          height: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Text style={{ color: "#999" }}>
                          Nenhum evento registrado
                        </Text>
                      </Box>
                    )}
                  </ContentContainer>
                )}
              </Box>
              <ContentContainer row>
                <Box
                  sx={styles.activeContainer}
                  onClick={() => handleChangeActivation()}
                >
                  <Box
                    sx={{
                      ...styles.checkIcon,
                      backgroundImage: locker.active
                        ? `url('/icons/green_check_icon.png')`
                        : `url('/icons/gray_check_icon.png')`,
                    }}
                  />
                  <Spacer horizontal />
                  <Box sx={styles.activeDoorTextContainer}>
                    <Text style={styles.regularText}>
                      {locker.active ? `Locker Ativo` : `Locker Inativo`}
                    </Text>
                  </Box>
                </Box>
                <Box
                  sx={styles.activeContainer}
                  onClick={() =>
                    window.open(
                      `http://www.tuimbox.com.br/locker/${id}`,
                      "_blank"
                    )
                  }
                >
                  <Box
                    sx={{
                      ...styles.checkIcon,
                      backgroundImage: `url('/icons/gray_contract.png')`,
                    }}
                  />
                  <Spacer horizontal />
                  <Box sx={styles.activeDoorTextContainer}>
                    <Text style={styles.regularText}>Acessar Catálogo</Text>
                  </Box>
                </Box>
              </ContentContainer>

              <ContentContainer gap={0}>
                <SmallSectionTitle title="Endereço" />
                <Spacer size="2" />
                <TextField
                  variant="standard"
                  label="Rua"
                  margin="dense"
                  name="rua"
                  fullWidth
                  value={locker.address?.rua}
                  onChange={handleChange}
                  InputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { color: "#888" } }}
                />
                <Box sx={{ display: "flex" }}>
                  <TextField
                    variant="standard"
                    label="Nº"
                    margin="dense"
                    name="numero"
                    value={locker.address?.numero}
                    onChange={handleChange}
                    InputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { color: "#888" } }}
                    style={{ maxWidth: 100 }}
                  />
                  <Spacer horizontal size="1" />
                  <TextField
                    variant="standard"
                    label="Complemento"
                    margin="dense"
                    name="complemento"
                    value={locker.address?.complemento}
                    onChange={handleChange}
                    InputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { color: "#888" } }}
                  />
                </Box>
                <Box sx={{ display: "flex" }}>
                  <TextField
                    variant="standard"
                    label="Cep"
                    margin="dense"
                    name="cep"
                    value={locker?.address?.cep}
                    onChange={handleChange}
                    InputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { color: "#888" } }}
                    style={{ maxWidth: 100 }}
                  />
                  <Spacer horizontal size="1" />
                  <TextField
                    variant="standard"
                    label="Bairro"
                    margin="dense"
                    name="bairro"
                    value={locker.address?.bairro}
                    onChange={handleChange}
                    InputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { color: "#888" } }}
                  />
                </Box>
                <Box sx={{ display: "flex" }}>
                  <TextField
                    variant="standard"
                    label="Estado"
                    margin="dense"
                    name="estado"
                    value={locker?.address?.estado}
                    onChange={handleChange}
                    InputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { color: "#888" } }}
                    style={{ maxWidth: 160 }}
                  />
                  <Spacer horizontal />
                  <TextField
                    variant="standard"
                    label="Cidade"
                    margin="dense"
                    name="cidade"
                    value={locker.address?.cidade}
                    onChange={handleChange}
                    InputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { color: "#888" } }}
                  />
                </Box>
              </ContentContainer>
            </Box>

            {locker?.boxCode && (
              <ContentContainer style={{ maxHeight: "auto" }}>
                <SmallSectionTitle title={`Abastecimento`} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {!!storeLockerData ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 2,
                          flex: 1,
                          padding: `8px 0px`,
                        }}
                      >
                        <QRCode
                          size={120}
                          value={storeLockerData?.volumes[0]?.expressNumber}
                        />
                        <Text>ou</Text>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Text style={styles.expressNumberText}>
                            {storeLockerData?.volumes[0]?.expressNumber}
                          </Text>
                        </Box>
                      </Box>
                      <Box>
                        <SecondaryButton
                          width="small"
                          text="Novo Abastecimento"
                          onClick={() => {
                            setStoreLockerData(null);
                            setSelectedProduct(null);
                          }}
                        />
                      </Box>
                    </>
                  ) : !!selectedProduct ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          flex: 1,
                        }}
                      >
                        <Text style={styles.regularText}>
                          Abastecer locker com
                        </Text>
                        <Text
                          style={styles.boldText}
                        >{`${selectedProduct?.name} (${selectedProduct?.designationSize})?`}</Text>
                      </Box>
                      <Box>
                        <PrimaryButton
                          width="small"
                          text="Iniciar abastecimento"
                          onClick={() => handleStoreProduct()}
                        />
                      </Box>
                    </>
                  ) : (
                    <Text style={styles.boldText}>
                      Selecione um produto para abastecer o locker
                    </Text>
                  )}
                </Box>

                <Grid
                  ref={productsContainer}
                  container
                  spacing={0}
                  sx={styles.productCardMainContainer}
                >
                  {products.map((product, index) => (
                    <Grid
                      key={index}
                      item
                      zeroMinWidth
                      onClick={() => handleSelectProduct(product)}
                      sx={{
                        ...styles.productCardContainer,
                        borderColor:
                          product._id === selectedProduct?._id
                            ? Colors.darkGreen
                            : "#ddd",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          position: "absolute",
                          top: 5,
                          right: 5,
                        }}
                      >
                        {product._id === selectedProduct?._id && (
                          <Box style={{ ...styles.checkIcon }} />
                        )}
                      </Box>
                      <Box
                        sx={{
                          ...styles.productCardContent,
                          backgroundImage: product?.urlImg
                            ? `url(${product.urlImg})`
                            : "none",
                        }}
                      />
                      <Text
                        style={{ ...styles.regularText, textAlign: "center" }}
                      >
                        {product.name}
                      </Text>
                      <Box sx={styles.designationSizeLabel}>
                        <Text
                          small="true"
                          bold="true"
                          style={{ color: "#aaa" }}
                        >
                          {product?.designationSize || "-"}
                        </Text>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </ContentContainer>
            )}
          </Box>
        </Box>
      </Box>
      {/* {newLocker ?
            <></>
            :
            <ContentContainer>
               <SmallSectionTitle title='Portas' />
               <LockerOverview
                  lockerId={id}
                  products={products}
                  alert={alert}
                  lockerName={locker?.name}
               />
               <Spacer size='3' />
            </ContentContainer>
         } */}
    </>
  );
};

const styles = {
  regularText: {
    fontSize: 14,
  },
  boldText: {
    fontSize: 14,
    fontWeight: 600,
  },
  expressNumberText: {
    fontSize: 24,
    fontWeight: 600,
  },
  activeContainer: {
    flex: 1,
    height: 30,
    backgroundColor: Colors.lightBackground,
    borderRadius: 2,
    display: "flex",
    alignItems: "center",
    padding: 1,
    "&:hover": {
      backgroundColor: Colors.hover,
      cursor: "pointer",
    },
  },
  checkIcon: {
    backgroundImage: `url('/icons/green_check_icon.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 22,
    height: 22,
    marginLeft: 1,
    "&:hover": {
      cursor: "pointer",
    },
  },
  activeDoorTextContainer: {
    flex: 1,
    marginRight: 1,
    display: "flex",
    justifyContent: "center",
  },
  productCardMainContainer: {
    width: 600,
    height: "100%",
    overflowX: "auto",
    display: "flex",
    boxSizing: "border-box",
  },
  productCardContainer: {
    position: "relative",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#ddd",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: 1,
    borderRadius: 3,
    margin: 0.5,
    width: 137,
    overflow: "hidden",
    "&:hover": {
      cursor: "pointer",
      borderWidth: 1,
      borderColor: "#09404766",
    },
  },
  productCardContent: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundOrigin: "content-box",
    width: 80,
    height: 110,
  },
  designationSizeLabel: {
    position: "absolute",
    top: 12,
    left: 0,
    padding: `2px 8px`,
    backgroundColor: "#f0f0f0",
    borderRadius: `0px 8px 8px 0px`,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
};
