import React, { useEffect, useState } from "react"
import api from "../../../services/api";
import { Spacer, Text, Box, SectionHeader, TextField, ContentContainer, Contracts } from '../../W3libComponents'
import { Link, useParams } from 'react-router-dom'
import { Colors } from "../../organisms/layout/Colors";
import { InternalNotes } from "../../organisms/internalNotes/internalNotes";
import { StatusLabel } from "../../organisms/layout/StatusLabel";
import { Row } from "../../organisms/table/DetailsRow";
import { formatTimestamp } from "../../../helpers/user/formatTimestamp";
import { SmallSectionTitle } from "../../organisms/layout/SmallSectionTitle";

const getFormatedDate = (createdAt) => {
   if (createdAt === '') return `-`

   createdAt = createdAt.split("T")
   let date = createdAt[0].split("-")
   let hour = createdAt[1].split(".")[0].split(":")
   return `${date[2]}/${date[1]}/${date[0]} às ${hour[0]}:${hour[1]}`
}

export const FeedbackDetails = ({ context }) => {

   const { user, setLoading, alert } = context
   const { id: feedbackId } = useParams()

   const [feedback, setFeedback] = useState({})
   const {
      _id = '',
      admin_comments = '',
      createdAt = '',
      id_user = '',
      message = '',
      status = '',
      subject = '',
      idCode = 0,
      phone = '',
      user_type = '',
      contracts = []
   } = feedback

   const {
      email = '',
      name = '',
      newsLetter = false,
   } = !!id_user ? id_user : feedback

   const statusOptions = [
      {
         value: 'Aberto',
         display: 'Aberto',
         color: Colors.red
      },
      {
         value: 'Em andamento',
         display: 'Em andamento',
         color: Colors.yellow
      },
      {
         value: 'Pendente',
         display: 'Pendente',
         color: Colors.yellow
      },
      {
         value: 'Resolvido',
         display: 'Resolvido',
         color: Colors.green
      }
   ]

   const loadFeedback = async () => {
      setLoading(true)
      try {
         const feedback = await api.get(`/v3/admin/feedback/${feedbackId}`)
         setFeedback(feedback.data)
         document.title = `TuimBox Admin - Feedback de ${feedback?.data?.user_type}`
      } catch (error) {
         console.log({
            message: 'Error while fetching feedback',
            error,
            feedbackId
         })
      } finally {
         setLoading(false)
      }
   }

   const handleChange = ({ target }) => {
      const { name, value } = target
      setFeedback({
         ...feedback,
         [name]: value
      })

   }

   useEffect(() => {
      loadFeedback()
   }, [])

   const getPhoneNumber = () => {
      let phoneNumber = id_user?.mobileNumber || 'Não informado'

      return (
         <Box sx={{ display: 'flex', gap: 1 }}>
            <Text style={styles.text}>{id_user?.mobileNumber || `-`}</Text>
            {id_user?.mobileNumber &&
               <Link to={{ pathname: `https://api.whatsapp.com/send/?phone=55${phoneNumber.replace(/\D/g, "")}` }} target="_blank">
                  <Box sx={styles.whatsappIcon} />
               </Link>
            }
         </Box>
      )
   }

   const updateFeedback = async () => {
      try {
         setLoading(true)
         await api.patch(`/v3/admin/feedback/${_id}`, { ...feedback })
         setLoading(false)
         alert.success('Feedback atualizado com sucesso!')
      } catch (error) {
         setLoading(false)
         alert.error('Desculpe. Não foi possível atualizar o feedback.')
      }
   }

   return (
      <>
         <SectionHeader
            title={`Feedback ${idCode}`}
            saveButton
            saveButtonAction={() => updateFeedback()}
         />
         <ContentContainer row>
            <Box sx={{ width: 500 }}>
               <Row
                  contained
                  columnGap={2}
                  // rowGap={0}
                  data={[
                     {
                        label: 'Status', description: (<StatusLabel
                           background='light'
                           currentStatus={feedback?.status}
                           dropDown
                           dropDownOptions={statusOptions}
                           dropDownOptionAction={(status) => setFeedback({ ...feedback, status })}
                        />)
                     },
                     { label: "Nome", description: `${name || id_user?.name} (${user_type})` },
                     { label: "E-mail", description: email || id_user?.email },
                     { label: "Celular", description: getPhoneNumber() },
                     { label: "Newsletter", description: newsLetter ? `Sim` : `Não` },
                     { label: "Enviado em", description: formatTimestamp({ timestamp: createdAt }) },
                     { label: "Assunto", description: subject },
                     { label: "Mensagem", description: message },
                  ]}
               />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: 600 }}>
               <InternalNotes
                  reference={{ type: 'Feedback', id: feedbackId }}
                  authorId={user._id}
               />
            </Box>
         </ContentContainer>

         <ContentContainer>
            <SmallSectionTitle title={`Contratos do usuário (${contracts.length})`} />
            <Contracts contracts={contracts} />
         </ContentContainer>

         <TextField
            multiline
            variant="outlined"
            color="primary"
            name="admin_comments"
            value={feedback.admin_comments}
            label="Observações"
            rows="8"
            style={{ width: '100%' }}
            InputLabelProps={{ style: { color: '#888' } }}
            InputProps={{ style: { fontSize: 14 } }}
            onChange={handleChange}
         />
      </>
   )
}

const UserContracts = ({ contracts }) => {

   const getContractStatus = (status) => {
      if (status === 'finished') { return { status: 'Finalizado', color: '#ddd' } }
      if (status === 'active') { return { status: 'Ativo', color: '#CCE4CF' } }
      if (status === 'abandon' || status === 'abandon-error') { return { status: 'Cancelado', color: '#F2E5CE' } }
   }

   return (
      <Box sx={{ marginTop: 2 }}>
         {contracts.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1).map((contract, index) =>
            <Link to={{ pathname: `/contracts/${contract._id}` }} target="_blank" style={{ textDecoration: 'none' }}>
               <Box sx={styles.contractCard}>
                  <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: 80 }}>
                     <Box style={{ backgroundImage: `url('${contract.products.urlImg}')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: 55, height: 55, marginBottom: 5 }} />
                  </Box>
                  <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>

                     {/* Product Name */}
                     <Box>
                        <Text style={styles.textTitle}>{contract.products.name}</Text>
                     </Box>

                     {/* Details */}
                     <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text style={styles.smallText}>Retirado em {getFormatedDate(contract.createdAt)}</Text>
                        {contract.returnDate && <Text style={styles.smallText}>Devolvido em {getFormatedDate(contract.returnDate)}</Text>}
                     </Box>
                     <Box style={{ backgroundColor: getContractStatus(contract.status).color, marginTop: 10, paddingLeft: 10, paddingRight: 10, borderRadius: 5, width: 100, display: 'flex', justifyContent: 'center' }}>
                        <Text style={{ ...styles.smallText, fontSize: '12px' }}>{getContractStatus(contract.status).status}</Text>
                     </Box>
                  </Box>
               </Box>
            </Link>
         )}
      </Box>
   )
}

const NoContracts = () => {
   return (
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
         <Text style={styles.smallText}>Usuário não possui contratos</Text>
      </Box>
   )
}

const styles = {
   headerContainer: {
      display: 'flex',
      paddingTop: 10,
      paddingBottom: 30,
   },
   userFeedbackForm: {
      display: 'flex',
      paddingTop: 15,
      paddingBottom: 15,
      marginBottom: 20
   },
   buttonsContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      flex: 1
   },
   button: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 100,
      height: 40,
      borderRadius: 5,
      marginRight: 5,
      paddingInline: 10
   },
   feedbackManagementContainer: {
      display: 'flex',
      paddingTop: 15,
      paddingBottom: 15,
   },
   sectionTitle: {
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: '#aaa',
      fontFamily: 'UbuntuBold'
   },
   textTitle: {
      fontFamily: 'UbuntuBold',
      fontSize: '14px',
   },
   text: {
      fontSize: '14px',
   },
   smallText: {
      fontSize: '13px',
   },
   dropDownContainer: {
      flex: 1,
      height: 35,
      backgroundColor: '#f5f5f5',
      border: '1px solid #ddd',
      borderRadius: 5,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 10,
      paddingRight: 10
   },
   dropDownOptionsContainer: {
      width: '99%',
      backgroundColor: '#f5f5f5',
      display: 'flex',
      borderRadius: 5,
      overflow: 'hidden',
      border: '1px solid #ddd',
      flexDirection: 'column',
      justifyContent: 'center',
      position: 'absolute',
      top: 35
   },
   dropDownOptionItem: {
      height: 35,
      display: 'flex',
      alignItems: 'center',
      borderTop: '1px solid #ccc',
      width: '100%',
      "&:hover": {
         backgroundColor: '#6b858e14',
      }
   },
   loadingContainer: {
      display: 'flex',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#ffffffbb'
   },
   contractCard: {
      backgroundColor: '#fff',
      padding: 2,
      marginBottom: 1,
      display: 'flex',
      alignItems: 'center',
      borderRadius: 2,
      flex: 1,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: Colors.hover,
      transition: 'all .1s ease-in-out',
      "&:hover": {
         transform: 'scale(1.05)',
         cursor: 'pointer'
      }
   },
   whatsappIcon: {
      backgroundImage: `url('/icons/green_square_whatsapp.png')`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width: 20,
      height: 20,
      // marginLeft: 1,
      "&:hover": {
         cursor: 'pointer'
      }
   }
}